import React from "react";

// Creating the context object and passing the default values.
const AuthContext = React.createContext({
  status: false,
  userProfile: {},
  login: () => {},
  logoutuser: () => {},
  isLoading: false
});

export default AuthContext;
