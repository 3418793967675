import React, { useContext, useState, useEffect } from "react";
import { useParams } from "react-router";
import { Container, Divider, Typography, AppBar } from "@material-ui/core";
import ThemeTablen from "../Table/ThemeTablen.jsx";
import CommonContext from "../../hooks/commonContext.js";
import { getFormatedTime, FormatedDates } from "../../services/helpers.js";
import { getOneIssue } from "../../services/apiservices/issues.js";
import { DetailsColumns } from "../../app/IssueManagement/IssuesManageTableColumns.js";
import ViewAttachments from "../../app/IssueManagement/IssueDetails/ViewAttachments.jsx";
import { getClientsList } from "../../services/apiservices/client.js";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery } from "@mui/material";
import Header from "../header/header.jsx";

const useStyles = makeStyles((theme) => ({
    customTooltip: {
        position: "absolute",
        margin: "auto",
        top: "15%",
        left: "176.5px",
        backgroundColor: '#fff',
        border: '1px solid #ccc',
        padding: '10px',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
        zIndex: 100,
        width: "195px",
    },
    tooltipArrow: {
        position: "absolute",
        margin: "auto",
        top: "30%",
        left: "162.5px",
        zIndex: 1000,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        boxShadow: 'none',
        borderBottomColor: '#ffffff',
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
      }
}));

const TooltipArrow = () => {
    return (
        <svg width="15" height="20" viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
            <polygon points="10,0 0,5 10,10" fill="#fff" stroke="#555" strokeWidth={0.2} />
        </svg>
    );
};

const RecipientsTooltip = ({styles}) => {
    return <div className={styles.customTooltip}>
        {"Please login to see details"}</div>;
};

const EscalateForm = ({IssueDetail}) => {
    return (
      <div className="issueDetails mb-20 formtxtbtm">
        <h3 className="formtxt">Description</h3>
        <p>{IssueDetail?.description}</p>
        <ViewAttachments attachments={IssueDetail?.attachments}/>
        <Divider fullWidth className="mt-10" />
      </div>
    );
  };

function PublicIssuePage(props) {
    const { issueId } = useParams();
    const commonContext = useContext(CommonContext);
    const [issueDetail, setIssueDetail] = useState([]);
    const [showTooltip, setShowTooltip] = useState(null);
    const styles = useStyles();
    const isMobile = useMediaQuery('(max-width:768px)');  

    const handleMouseOver = (responseItem) => {
        setShowTooltip(responseItem);
    };

    const handleMouseLeave = () => {
        setShowTooltip(null);
    };
    const fetchIssuesDetails = async () => {
        if (issueId && issueId !== "") {
            commonContext?.setLoader(true);
            let emails = [];
            await getClientsList(
                {},
                (res) => {
                    if (res) {
                        emails = res.map(client => client.contact[0]?.email || null).filter(email => email);
                    } else {
                        emails = [];
                    }
                }
            );
            await getOneIssue(
                issueId,
                async (res) => {
                    if (res && res.status === true) {
                        let details = {
                            ...res.data,
                            issuueId: `${'IS-'}${String(res.data?.issueNumber).padStart(4, '0')}`,
                            employeeName: res.data?.employee?.name,
                            clientName: res.data?.client?.name,
                            dateOfRequest: res.data?.dateOfRequest,
                            clientData: emails,
                        };
                        let responses =
                            details?.responses &&
                            [...Object.keys(details?.responses)].map((key) => {
                                return { ...details?.responses[key], id: key };
                            });
                        responses = responses
                            ? responses.sort((a, b) => (a.respondOn > b.respondOn ? 1 : -1))
                            : [];
                        setIssueDetail({ ...details, responses: responses });
                        commonContext?.setLoader(false);
                    } else {
                        commonContext?.setLoader(false);
                    }
                },
                (resError) => {
                    console.log("reserror", resError);
                    commonContext?.setLoader(false);
                }
            );
            commonContext?.setLoader(false);
        }
    };

    useEffect(() => {
        fetchIssuesDetails();
    }, [issueId]);

    const getEmailDisplay = (item) => {
        return <strong>{item.respondBy.name}</strong>;
    };

    return (
        <>

        <CssBaseline />
        <AppBar position="fixed" className={styles.appBar}>
            <Header />
        </AppBar>
        <Container>

        <span className="d-flex flex-justify-center color-danger fw-bold" style={isMobile ? {marginTop:"15px", marginLeft:"15px"} : {marginTop:"15px"}}>This page is for viewing purposes only. 
            To respond to the issue, please login or reply to the email you received.</span>
        
            <div className="sectionBox deissue1">
            <ThemeTablen
             rows={[issueDetail]} 
             headCells={DetailsColumns}
             hidePagination
            />        
            <EscalateForm
            IssueDetail={issueDetail}
          />
            <div>
                    <div className="issueDetails">
                        {issueDetail?.responses?.sort((a, b) => b.respondOn - a.respondOn).map((responseItem, index) => (
                            <div key={index} className="mb-15 responc">
                                <Typography variant="subtitle1">
                                    <em>
                                        <div className="d-flex flex-space-between">
                                            <span className="w-75">{getEmailDisplay(responseItem)}</span>
                                            <span className="w-20">{FormatedDates(responseItem.respondOn)} {getFormatedTime(responseItem.respondOn)}</span>
                                        </div>
                                    </em>
                                </Typography>
                                <Typography variant="body1" className="pv-12">{responseItem.description}</Typography>
                                <div className="d-flex flex-space-between flex-end position-relative">
                                    <div className="issuelink" onMouseOver={() => handleMouseOver(responseItem)} onMouseLeave={handleMouseLeave}>
                                        Included Recipients
                                    </div>
                                    {showTooltip === responseItem && responseItem?.recipients?.length > 0 && (
                                        <>
                                            <div className={styles.tooltipArrow}><TooltipArrow /></div>
                                            <RecipientsTooltip styles={styles} />
                                        </>
                                    )}
                                    <ViewAttachments attachments={responseItem.attachments} />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </Container>
        </>
    );
}

export default PublicIssuePage;
