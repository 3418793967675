import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import Loader from "../components/AppLoader/Loader";
import DefaultLayouts from "../layouts/defaultLayouts";
import Unauthorized from "../components/Unauthorized/Unauthorized";
import PublicIssuePage from "../components/PublicComponent/PublicIssuePage";

/**
 * Manage App routes layout and authentication
 * @param {*} props
 * @returns
 */
const AppRoutes = (props) => {
  
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route
          path="/:issueId"
          element={
            <DefaultLayouts {...props} isSecure={true}>
              <PublicIssuePage />
            </DefaultLayouts>
          }
        />

        <Route
          path="*"
          element={
            <DefaultLayouts {...props} isSecure={true}>
              <Unauthorized />
            </DefaultLayouts>
          }
        />
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
