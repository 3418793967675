import moment from 'moment';
/**
 *
 * @param {array} a
 * @param {array} b
 * @param {*} orderBy
 * @returns comparition of two array
 * used for table sorting dynamic
 */
export function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

/**
 *
 * @param {*} order
 * @param {*} orderBy
 * @returns sorted list of array by give order
 */
export function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

/**
 *
 * @param {array} array
 * @param {*} comparator
 * @returns sort given array through comparator
 */
export function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {

    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export function getFormatedDate(timestamp) {
  return timestamp
    ? moment(Number(timestamp)).format('MMMM D, YYYY')
    : timestamp;
}
export function FormatedDates(timestamp) {
  return timestamp
    ? moment(Number(timestamp)).format('MMM D, YYYY')
    : timestamp;
}
export function getFormatedTime(timestamp) {
  return timestamp ? moment(Number(timestamp)).format('HH:mm:ss A') : timestamp;
}
