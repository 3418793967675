import { firebase } from '../firebase-config';
import { COLLECTIONS, BaseUrl } from '../config';
import { getLoginToken } from '../storage';
import axios from 'axios';

/**
 * Get Issue detail by issue id
 * @param {*} id  : issueId
 * @param {*} onSuccess
 * @param {*} onError
 */
export async function getOneIssue(id, onSuccess, onError) {
  const authHeaders = { Authorization: `Bearer ${getLoginToken()}` };
  
  const axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      withCredentials: true,
      ...authHeaders,
    },
  };

  try {
    const response = await axios.post(
      `${BaseUrl}getIssueDetails`,
      { issueId: id },
      axiosConfig
    );

    if (response.data.status) {
      const data = response.data.result;
      onSuccess && onSuccess({ status: true, data });
    } else {
      onError && onError({ status: false, error: response.data.result });
    }
  } catch (error) {
    onError && onError({ status: false, error });
  }
}
