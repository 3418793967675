import { firebase } from '../firebase-config';
import { BaseUrl, COLLECTIONS } from '../config';
import axiosInstance from '../axios';

const collection = COLLECTIONS.CLIENT;
/**
 * Get Document list from client collection
 * @param {*} formData : can be use for filter in future
 * @param {*} onSuccess
 * @param {*} onError
 */
export const getClientsList = async (formData, onSuccess, onError) => {
  let colRef = firebase.firestore().collection(collection);
  colRef
    .get()
    .then((querySnapshot) => {
      let results = [];
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        results.push({ id: doc.id, ...data });
      });
      onSuccess && onSuccess(results);
    })
    .catch((error) => {
      console.log('Error getting documents: ', error);
      onError && onError(error);
    });
};
