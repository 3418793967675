import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CommonContext from "../hooks/commonContext";
import Footer from "../components/footer/footer";
import Loader from "../components/AppLoader/Loader";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  logoutLink:{
    position:'fixed',
    bottom:5,
    width: 240,
    left: 0,
    paddingLeft: 20,
    cursor: 'pointer',
    backgroundColor:'#343a45',
    "&:hover": {
      backgroundColor:'#00000044',
      cursor: 'pointer',
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: 'none',
    borderBottomColor: '#ffffff',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  toolbar: {
    ...theme.mixins.toolbar,
    backgroundColor: "#343a45",
    minHeight: "auto",
  },
  toolbarmirror: { ...theme.mixins.toolbar, backgroundColor: "transparent" },
  drawerPaper: {
    width: drawerWidth,
    border: 'none',
    boxShadow: '1px 2px 3px rgba(0,0,0,0.4)',
    backgroundColor: "#343a45",
  },
  content: {
    flexGrow: 1,
  },
  btnLogout: {
    color:'#fff'
  },
}));

const DefaultLayouts = (props) => {
  const [layoutLoader, setLayoutLoader] = useState(false);
  const classes = useStyles();

  return (
    <>
      <CommonContext.Provider
        value={{
          loader: layoutLoader,
          setLoader: setLayoutLoader,
        }}
      >
        <div className={`DefaultContainer `}>
          {layoutLoader && <Loader />}
          <div className={classes.root}>
            <main className={classes.content}>
              <div className={classes.toolbarmirror} />
              <div className="content-wrap MuiContainer-root">{props.children}</div>
            </main>
            <Footer />
          </div>
        </div>
      </CommonContext.Provider>
    </>
  );
};

export default DefaultLayouts;
