/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AuthContext from "../hooks/authContext";
import AppRoutes from "./app-route";
import theme from "../theme";
import Loader from "../components/AppLoader/Loader";
import { ThemeProvider } from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core";
import { setLoginToken, clearLoginToken } from "../services/storage";
import { firebase } from "../services/firebase-config";
import { getCurrentUser } from "../services/firebase"

function App(props) {
  const [authstatus, setauthstatus] = useState(false);
  const [userProfile, setUserProfile] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    login();
  }, []);

  const login = async (token) => {
    setIsLoading(true);

    return firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        const userData = firebase.auth().currentUser;
          
        let currentuser = await getCurrentUser();
        
        if (
          Object.keys(currentuser?.userData).length !== 0 &&
          currentuser?.userData.constructor === Object
        ) {
          setLoginToken(currentuser?.token);


          setUserProfile({...currentuser?.userData, lastLogin: userData?.metadata?.lastSignInTime});
          setauthstatus(true);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setauthstatus(false);
          clearLoginToken();
        }
      } else {
        setauthstatus(false);
        setIsLoading(false);
      }
    });
  };

  return (
    <AuthContext.Provider
      value={{
        status: authstatus,
        userProfile: userProfile,
        login: login,
        isLoading: isLoading,
      }}
    >
      <ThemeProvider theme={theme}>
        {isLoading === true && <Loader />}
        <CssBaseline />
        <Router>{isLoading !== true && <AppRoutes {...props} />}</Router>
      </ThemeProvider>
    </AuthContext.Provider>
  );
}

export default App;
